/**
 * use vue-i18n-next and  vite-plugin-vue-i18n
 * https://github.com/intlify/bundle-tools/tree/main/packages/vite-plugin-vue-i18n
 * https://github.com/intlify/bundle-tools/tree/main/packages/vite-plugin-vue-i18n
 */

// import { createI18n } from 'vue-i18n'

export const install = () => {
  // const messages = Object.fromEntries(
  //   Object.entries(import.meta.glob<any>('/src/locales/*.ts', { eager: true })).map(([key, value]) => {
  //     return [key.slice(13, -3), value.default]
  //   })
  // )

  // const i18n = createI18n({
  //   locale: 'vi',
  //   messages,
  // })
  // app.use(i18n)
}
