import { doctorStatus, transferStatus } from '@/constants/define'

export const inject = () => ({
  getDoctorStatus(key: string) {
    return doctorStatus.label?.[key] ?? ''
  },
  getDoctorStatusColor(key: string) {
    return doctorStatus.color?.[key] ?? ''
  },
  getTransferColor(key: string) {
    return transferStatus.color?.[key] ?? ''
  },
})
