// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'
// Import Quasar css
import 'quasar/src/css/index.sass'
import langVi from 'quasar/lang/vi'

import { Quasar, Notify, Dialog, SessionStorage, LocalStorage, Loading } from 'quasar'

const config = {
  plugins: { Notify, Dialog, SessionStorage, LocalStorage, Loading }, // import Quasar plugins and add here
  lang: langVi,
  /*
  config: {
    brand: {
      // primary: '#e46262',
      // ... or all other brand colors
    },
    notify: {...}, // default set of options for Notify Quasar plugin
    loading: {...}, // default set of options for Loading Quasar plugin
    loadingBar: { ... }, // settings for LoadingBar Quasar plugin
    // ..and many more (check Installation card on each Quasar component/directive/plugin)
  }
  */
}

export const install = (app: any) => {
  app.use(Quasar, config)
}
