<template>
  <div class="q-pa-md">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PageContainer',
})
</script>
<style lang="scss" scoped>
</style>
