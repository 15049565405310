import { AppRole } from '@/constants/enums'
import shipperIcon from '@/assets/img/movers-icon.svg?url'
import staffIcon from '@/assets/img/customer-care-icon.svg?url'

export const navConfigs: RouterLink[] = [
  {
    name: 'dashboard', // uniq
    title: 'Dashboard',
    icon: 'dashboard',
    path: '/dashboard',
    level: 0,
    groups: [AppRole.admin, AppRole.staff],
    children: [],
  },
  {
    name: 'doctors',
    title: 'Quản lý Bác sĩ',
    icon: 'group',
    path: '/doctors',
    level: 0,
    groups: [AppRole.admin, AppRole.staff],
    children: [],
  },
  {
    name: 'setting',
    title: 'Cấu hình',
    icon: 'settings_suggest',
    path: '/settings',
    level: 0,
    groups: [AppRole.admin, AppRole.staff],
    children: [],
  },
]

export const guardConfig = {
  children: navConfigs,
}

export const getRedirectConfig = (role: string) => {
  const config = {
    [AppRole.admin]: '/doctors',
    [AppRole.accountant]: '/doctors',
    [AppRole.staff]: '/doctors',
  } as AnyObject

  return config?.[role] ?? '/'
}
