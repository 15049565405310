const __pages_import_0__ = () => import("/src/pages/settings/index.vue");
const __pages_import_1__ = () => import("/src/pages/doctors/index.vue");
const __pages_import_2__ = () => import("/src/pages/dashboard/index.vue");
const __pages_import_3__ = () => import("/src/pages/blank/reset-password.vue");
const __pages_import_4__ = () => import("/src/pages/blank/login.vue");
const __pages_import_5__ = () => import("/src/pages/blank/403.vue");
import __pages_import_6__ from "/src/pages/index.vue";
const __pages_import_7__ = () => import("/src/pages/blank/_.vue");
const __pages_import_8__ = () => import("/src/pages/doctors/_id/index.vue");

const routes = [{"name":"settings","path":"/settings","component":__pages_import_0__,"props":true},{"name":"doctors","path":"/doctors","component":__pages_import_1__,"props":true,"meta":{"requiresAuth":true,"title":"Quản lý Bác sĩ"}},{"name":"dashboard","path":"/dashboard","component":__pages_import_2__,"props":true,"meta":{"requiresAuth":true,"title":"Trang chủ"}},{"name":"reset-password","path":"/reset-password","component":__pages_import_3__,"props":true,"meta":{"layout":"blank","title":"Đặt lại mật khẩu"}},{"name":"login","path":"/login","component":__pages_import_4__,"props":true,"meta":{"layout":"blank","title":"Đăng nhập"}},{"name":"403","path":"/403","component":__pages_import_5__,"props":true,"meta":{"layout":"blank"}},{"name":"index","path":"/","component":__pages_import_6__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_7__,"props":true,"meta":{"layout":"blank","requiresAuth":true}},{"name":"doctors-id","path":"/doctors/:id","component":__pages_import_8__,"props":true,"meta":{"requiresAuth":true}}];

export default routes;